<template>
  <div>
    <div class="conter">
      <h1>Sitemap</h1>
      <div class="conter-font">
        <h3>
          <span>Products</span>
        </h3>
        <div class="conter-font-baibo">
          <dl>
            <dt><a href="/product-center" target="_blank">Product Center</a></dt>
            <dd>
              <a href="/what-is-a-software-defined-camera" target="_blank">
              <em>
                <img class="box_imgss" src="../../assets/image/sitemap/jianto.png" alt="">
              </em>Software-Defined Camera</a>
            </dd>
          </dl>
        </div>
      </div>
      <div class="conter-font">
        <h3>
          <span>Technical Support</span>
        </h3>
        <div class="conter-font-baibo">
          <dl>
            <dt><a href="/support" target="_blank">Technical Support</a></dt>
          </dl>
        </div>
      </div>
      <div class="conter-font">
        <h3>
          <span>Resource Center</span>
        </h3>
        <div class="conter-font-baibo">
          <dl>
            <dt><a href="/documentation" target="_blank">Resources</a></dt>
          </dl>
        </div>
      </div>
      <div class="conter-font">
        <h3>
          <span>About HOLOWITS</span>
        </h3>
        <div class="conter-font-baibo">
          <dl>
            <dt><a href="/news" target="_blank">News Room</a></dt>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less">
@media (min-width: 991px) {
  .conter {
    width: 1140px;
    margin: 0 auto;
    h1 {
      font-size: 32px;
      color: #666666;
      font-weight: 400;
    }
    .conter-font {
      h3 {
        font-size: 22px;
        color: #333;
        padding: 28px 0 18px 4px;
        line-height: 24px;
      }
      .conter-font-baibo {
        padding: 17px;
        color: #666;
        background: #fafafa;
        dl {
          padding: 17px 36px;
          overflow: hidden;
          dt {
            font-size: 16px;
            line-height: 1.4;
            color: #333;
            font-family: 'LT_Bold';
            margin-bottom: 10px;
            a {
              color: #333;
            }
            a:hover {
              text-decoration: underline;
            }
          }
          dd:hover {
            text-decoration: underline;
          }
          dd {
            width: 30%;
            float: left;
            padding: 5px 10px 5px 0;
            position: relative;

            em {
              .box_imgss {
                width: 16px;
                height: 16px;
                margin-right: 5px;
                vertical-align: text-top;
              }
            }
            a {
              padding: 0 0 0 17px;
              width: 100%;
              display: inline-block;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .conter {
    // width: 1140px;
    margin: 0 auto;
    padding: 30px;
    h1 {
      font-size: 32px;
      color: #666666;
      font-weight: 400;
    }
    .conter-font {
      h3 {
        font-size: 22px;
        color: #333;
        padding: 28px 0 18px 4px;
        line-height: 24px;
      }
      .conter-font-baibo {
        padding: 17px 20px;
        color: #666;
        background: #fafafa;
        dl {
          padding: 17px 0px;
          overflow: hidden;
          dt {
            font-size: 16px;
            line-height: 1.4;
            color: #333;
            font-family: 'LT_Bold';
            margin-bottom: 10px;
            a {
              color: #333;
            }
          }
          dd {
            width: 100%;
            float: left;
            padding: 5px 10px 5px 0;
            position: relative;
            em {
              .box_imgss {
                width: 16px;
                height: 16px;
                margin-right: 5px;
                vertical-align: text-top;
              }
            }
            a {
              font-size: 14px;
              line-height: 1.4;
              color: #666;
              display: inline-block;
              padding-left: 17px;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
}
</style>
